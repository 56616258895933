<template>
    <div class="p-col-12 p-card relative">
        <!-- <div class="p-d-flex"><breadcrumb :class="'p-col-12'" :home="home" :model="breadcrumb" /></div> -->
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <TabMenu :model="tabs" class="p-pb-3" />

        <router-view @loadingChange="loadingChange" />
    </div>
</template>

<script>
export default {
    name: 'index',
    data: () => ({
        filters: {},
        loading: true,
        userTitle: '',
        home: { to: '/', icon: 'pi pi-home' },
        tabs: [
            {
                label: 'Мой профиль',
                to: '/person/userProfile',
            },
            {
                label: 'Моя организация',
                to: '/person/userOrganization',
            },
        ],
    }),
    methods: {
        loadingChange(payload = false) {
            this.loading = payload;
        },
    },
    computed: {
        breadcrumb() {
            const re = /:userId/gi;
            const params = { ...this.$route.params };

            return this.$route.meta.breadcrumb.map((breadcrumb) => {
                if (breadcrumb.to.indexOf(':userId') >= 0) {
                    return {
                        ...breadcrumb,
                        label: breadcrumb.label == 'Редактирование' ? this.userTitle : breadcrumb.label,
                        to: breadcrumb.to.replace(re, params.userId),
                    };
                }

                return breadcrumb;
            });
        },
    },
    beforeRouteUpdate(to, from, next) {
        this.loading = true;
        next();
    },
};
</script>


<style lang='scss' scoped>
.relative {
    position: relative;
}
.p-component-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
.pi-spinner {
    font-size: 2rem;
}
::v-deep .p-tabmenu {
    .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        font-size: 13px;
        line-height: 16px;
    }
}
</style>
